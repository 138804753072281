/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #007BFF;
  border-radius: 8px 8px 0 0;
  color: white;
}

.logo {
  height: 40px;
}

.menu a {
  margin-left: 20px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.menu a:hover {
  text-decoration: underline;
}

/* Tabs Styles */
.tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;
}

.tabs button {
  flex: 1;
  padding: 10px;
  background-color: #f8f8f8;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-bottom 0.3s;
  font-size: 16px;
  color: #333;
}

.tabs button:hover {
  background-color: #e0e0e0;
}

.tabs .active {
  border-bottom: 2px solid #007BFF;
  background-color: #fff;
  font-weight: bold;
  color: #007BFF;
}

/* Viewer Styles */
.viewer {
  margin-top: 20px;
}

/* Textarea Styles */
textarea {
  width: 97%;
  height: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
  resize: vertical;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 14px;
}

th {
  background-color: #007BFF;
  color: white;
}

/* Toolbar Button Styles */
.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.toolbar button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.toolbar button:hover {
  background-color: #0056b3;
}

.toolbar button:active {
  transform: scale(0.98);
}

/* Footer Styles */
.footer {
  margin-top: 40px;
  padding: 20px;
  background-color: #007BFF;
  color: white;
  text-align: center;
  border-radius: 0 0 8px 8px;
  font-weight: bold;
}
